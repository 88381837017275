import React, { useEffect, useRef, useState } from "react";
import Typewriter from "typewriter-effect/dist/core";
//framer motion
import { motion } from "framer-motion";
import Animation from "./Animation";
const mySound = require("../assets/me.m4a");
const otherSound = require("../assets/alikiba.mp3");

const Home = () => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [counter, setCounter] = useState(0);
  const [change, setChange] = useState(false);
  const typewriterRef = useRef(null);
  const isTypingRef = useRef(false);
  const typewriterInstance = useRef(null);

  // An array of text snippets to type out
  const textSnippets = [
    " Hi Lisa...",
    " You know, they say opposites attract.",
    " But with us,",
    " it's our similarities that drew us closer.",
    " Weird, right?",
    " Tuna mambo mengi kwa pamoja,",
    " from our shared laughs watching Mr Bones,",
    " to deep conversations about life. ",
    " Yet sometimes we really piss each other off",
    " —ni kama pilipili kwenye macho,",
    " but then, isn't life a little bland without a bit of spice?",
    " And for every moment you've pissed me off, there's a dozen more where you've brought me pure joy.",
    " We've been through a few rough patches already, siyo? ",
    " Riding every high and low, with a bond that only seems to grow stronger.",
    " I've taken my time, pondering, wondering—what does the future hold?",
    " Nilikuwa najiuliza, am I doing the right thing?",
    " And here's my answer,",
    " loud and clear,",
    " in this very moment, with every beat of my heart—ndiyo,",
    " I am.",
    " Lol, can you tell where this is going?",
    " I'm sure you can.",
    " Lisa, can you share the future with me?",
    " Utakuwa mpenzi wangu?",
  ];

  const addText = () => {
    // Check if there's more text to add and if it's not currently typing
    setCounter(counter + 1);
    if (currentTextIndex < textSnippets.length && !isTypingRef.current) {
      if (counter == 21) {
        return;
      }

      if (counter >= 0) {
        const audioEl = document.querySelector(".audio-element");
        //reduce volume
        audioEl.volume = 0.1;
        audioEl.play().catch((e) => console.error("Error playing audio:", e));
      }

      if (counter >= 2) {
        const audioEl = document.querySelector(".audio-element2");
        //reduce volume
        audioEl.volume = 0.002;
        audioEl.play().catch((e) => console.error("Error playing audio:", e));
      }

      isTypingRef.current = true; // Indicate that typing is in progress

      typewriterInstance.current
        .typeString(textSnippets[currentTextIndex])
        .callFunction(() => {
          isTypingRef.current = false; // Typing finished
          setCurrentTextIndex(currentTextIndex + 1); // Prepare the next snippet
        })
        .start();
    }
  };

  useEffect(() => {
    if (!typewriterRef.current) {
      return;
    }

    // Initialize the typewriter effect only once
    if (!typewriterInstance.current) {
      typewriterInstance.current = new Typewriter(typewriterRef.current, {
        loop: false,
        autoStart: false,
        delay: 50,
      });

      addText(); // Start with the first text snippet
    }
  }, [change]);

  return (
    <>
      <div className="lg:hidden flex justify-center items-center h-screen text-xl  p-4">
        This version can only be displayed on laptop or desktop computers.
        Please use a larger screen with sound on.
      </div>
      <div
        className={`hidden relative lg:flex flex-col h-full w-full overflow-hidden justify-center items-center gap-8 ${
          counter >= 22 ? "bg-black text-white" : "bg-white"
        } transition-all duration-1000 ease-linear`}
      >
        <div className="flex flex-col  gap-8 items-start justify-between w-1/2 text-2xl">
          <div ref={typewriterRef}></div>
          <button
            className={`${
              counter >= 22 ? "bg-white text-black" : "bg-black text-white"
            } transition-all duration-700 ease-in-out text-lg py-2 px-4 rounded-md`}
            onClick={() => addText()}
          >
            {counter <= 25
              ? "Continue"
              : "Take your time. When you are done, I will be waiting for your answer at home."}
          </button>
          {counter >= 22 && (
            <motion.div
              className="absolute -bottom-5 right-0"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { duration: 5, ease: "anticipate" },
              }}
              viewport={{ once: true, amount: 0.5 }}
            >
              <Animation
                height={300}
                width={300}
                animationData={require("../assets/leaf.json")}
              />
            </motion.div>
          )}
          {counter > 23 && (
            <motion.div
              className="absolute top-10 left-2"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { duration: 5, ease: "anticipate" },
              }}
              viewport={{ once: true, amount: 0.5 }}
            >
              <Animation
                height={400}
                width={500}
                animationData={require("../assets/birds.json")}
              />
            </motion.div>
          )}
        </div>
        <div>
          <audio className="audio-element">
            <source src={mySound}></source>
          </audio>
          <audio className="audio-element2">
            <source src={otherSound}></source>
          </audio>
        </div>
      </div>
    </>
  );
};

export default Home;
