import Lottie from "react-lottie";

export default function Animation({ height, width, animationData }) {
  const loader = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return <Lottie options={loader} height={height} width={width} />;
}
